import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  FullPhoto,
  Header,
  Hero,
  Layout,
  Navigation,
  TextBox,
  WhatWeDid,
} from "components/CaseLayout";
import { STREETWEAR } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Streetwear = () => {
  const data = useCaseData(STREETWEAR);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo}/>
      <Hero color={data.hero.color} data={data.hero}/>
      <Navigation type={STREETWEAR}/>
      <WhatWeDid data={data.what_we_did}/>
      <Header color={data.color} data={data.headers.first}/>
      <FullPhoto data={data.full_images.first}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.first}/>
      <FullPhoto data={data.full_images.second}/>
      <FullPhoto data={data.full_images.third}/>
      <TextBox data={data.text_boxes.second}/>
      <FullPhoto data={data.full_images.fourth}/>
      <FullPhoto data={data.full_images.fifth}/>
      <FullPhoto data={data.full_images.sixth}/>
      <TextBox data={data.text_boxes.third}/>
      <FullPhoto data={data.full_images.seventh}/>
      <ContactForm/>
    </Layout>
  );
};

export default Streetwear;
